// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-edit-tag-page-js": () => import("./../../src/Templates/EditTagPage.js" /* webpackChunkName: "component---src-templates-edit-tag-page-js" */),
  "component---src-templates-error-page-js": () => import("./../../src/Templates/ErrorPage.js" /* webpackChunkName: "component---src-templates-error-page-js" */),
  "component---src-templates-landing-page-index-js": () => import("./../../src/Templates/LandingPage/index.js" /* webpackChunkName: "component---src-templates-landing-page-index-js" */),
  "component---src-templates-profile-page-index-js": () => import("./../../src/Templates/ProfilePage/index.js" /* webpackChunkName: "component---src-templates-profile-page-index-js" */),
  "component---src-templates-redirect-page-js": () => import("./../../src/Templates/RedirectPage.js" /* webpackChunkName: "component---src-templates-redirect-page-js" */),
  "component---src-templates-social-login-page-js": () => import("./../../src/Templates/SocialLoginPage.js" /* webpackChunkName: "component---src-templates-social-login-page-js" */),
  "component---src-templates-social-login-result-page-index-js": () => import("./../../src/Templates/SocialLoginResultPage/index.js" /* webpackChunkName: "component---src-templates-social-login-result-page-index-js" */),
  "component---src-templates-success-page-js": () => import("./../../src/Templates/SuccessPage.js" /* webpackChunkName: "component---src-templates-success-page-js" */)
}

